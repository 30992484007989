"use client"

import useClerkSWR from "@/hooks/useClerkHook";
import { CreditData } from "@/lib/utils";
import { useUser } from "@clerk/nextjs";
import { Badge } from "./ui/badge";

export const CREDITS_KEY = (tournamentId: number | undefined) => {
    return `${process.env.NEXT_PUBLIC_API_ENDPOINT}/credits?tournamentId=${tournamentId}`;
}

function CreditsBox({ tournamentId, token }: { tournamentId: number, token: string }) {
    const { isSignedIn } = useUser()

    const fetcher = (url: string) => fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}` as string
        }
    }).then((res) => res.json());

    const { data: creditData, isLoading: creditDataLoading, error }: { data: CreditData, isLoading: boolean, error: any } = useClerkSWR(
        CREDITS_KEY(tournamentId)
    );

    if (error) {
        console.log(error)
    }

    return (
        <>
            {isSignedIn && <Badge variant={"secondary"} className="text-white absolute top-8 right-4">
                {creditDataLoading ? 'Loading...' : `${creditData?.data?.balance || 0} Credits`}
            </Badge>}
        </>

    )
}

export default CreditsBox