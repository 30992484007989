'use client';

import {
    darkTheme,
    getDefaultConfig,
    RainbowKitProvider
} from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { http, WagmiProvider } from 'wagmi';

const queryClient = new QueryClient();

import { coinbaseWallet, metaMaskWallet, trustWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import { base } from 'viem/chains';
coinbaseWallet.preference = 'smartWalletOnly';

export const config = getDefaultConfig({
    appName: 'Metacade Tournaments',
    projectId: '1f9ac310962d08ec19ea4dc906b495ac',
    chains: [base],
    multiInjectedProviderDiscovery: false,
    appDescription: 'Metacade Tournaments',
    wallets: [
        { groupName: 'Recommended', wallets: [coinbaseWallet] },
        { groupName: 'Others', wallets: [metaMaskWallet, walletConnectWallet, trustWallet] },
    ],
    ssr: true,
    transports: {
        [base.id]: http(process.env.NEXT_PUBLIC_BASE_RPC_ENDPOINT),
    },
    appIcon: 'https://api.metafuse.me/assets/metacade/metacade.webp',
})


const WalletProvider = ({ children }: any) => {
    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                <RainbowKitProvider theme={darkTheme()}>
                    {children}
                </RainbowKitProvider>
            </QueryClientProvider>
        </WagmiProvider >
    );
};


export default WalletProvider;